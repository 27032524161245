import dayAPI from '~/utils/dayAPI';
import { Enumeration } from '../abstractions';
import { TimeOnly, TradingSession, IntradayTradingSessions, TradingSessionTime, } from '../value-objects';
export class MarketHours extends Enumeration {
    _sessions = [];
    static Undefined;
    static TWSE;
    static TFE_Standard;
    static TFE_AM;
    static TFE_PM;
    static CME;
    static Crypto;
    static SGX_CN;
    static HKF;
    static EUREX;
    static IUS;
    static CBT;
    static CBT_Agricultural;
    static IUS_Soft;
    static SGX_TWN;
    static SGX_NK;
    constructor(name, value) {
        super(name, value);
    }
    next(date) {
        const currentIndex = this._sessions.findIndex(session => session.isOpen(date));
        if (currentIndex >= 0) {
            return currentIndex + 1 >= this._sessions.length
                ? this._sessions[0]
                : this._sessions[currentIndex + 1];
        }
        const baseDate = date || dayAPI();
        const baseTime = TimeOnly.parse(baseDate.format('HH:mm:ss'));
        // Find next open today
        const nextSessionToday = this._sessions.find(session => session.openingTime.time.isAfter(baseTime) &&
            baseDate.weekday() === session.openingTime.dayOfWeek);
        if (nextSessionToday) {
            return nextSessionToday;
        }
        const tomorrowSession = this._sessions.find(session => session.openingTime.dayOfWeek > baseDate.weekday());
        if (tomorrowSession) {
            return tomorrowSession;
        }
        return this._sessions[0];
    }
    previous(date) {
        const currentIndex = this._sessions.findIndex(session => session.isOpen(date));
        if (currentIndex >= 0) {
            return currentIndex - 1 < 0
                ? this._sessions[this._sessions.length - 1]
                : this._sessions[currentIndex - 1];
        }
        return this.previousOf(this.next(date || dayAPI()));
    }
    intraday(date) {
        const baseDate = date || dayAPI();
        const sessions = new IntradayTradingSessions(baseDate, this.name);
        const lastSession = this.current(baseDate) || this.previous(baseDate);
        if (!lastSession) {
            throw new Error('ERROR indraday()');
        }
        sessions.add(lastSession);
        // const isCrossDay = this.intradayClosing().isSameOrBefore(this.intradayOpening())
        const hasMultiSession = !(lastSession.openingTime.time.equals(this.intradayOpening()) &&
            lastSession.closingTime.time.equals(this.intradayClosing()));
        if (hasMultiSession) {
            if (lastSession.openingTime.time.equals(this.intradayOpening())) {
                const next = this.nextOf(lastSession);
                sessions.add(next);
                if (!next.closingTime.time.equals(this.intradayClosing())) {
                    sessions.add(this.nextOf(next));
                }
            }
            else if (lastSession.closingTime.time.equals(this.intradayClosing())) {
                const previous = this.previousOf(lastSession);
                sessions.insert(previous, 0);
                if (!previous.openingTime.time.equals(this.intradayOpening())) {
                    sessions.insert(this.previousOf(previous), 0);
                }
            }
            else if (!lastSession.openingTime.time.equals(this.intradayOpening()) &&
                !lastSession.closingTime.time.equals(this.intradayClosing())) {
                const next = this.nextOf(lastSession);
                const previous = this.previousOf(lastSession);
                if (next.closingTime.time.equals(this.intradayClosing())) {
                    sessions.add(next);
                }
                if (previous.openingTime.time.equals(this.intradayOpening())) {
                    sessions.insert(previous, 0);
                }
            }
        }
        return sessions;
    }
    current(date) {
        return this._sessions.find(session => session.isOpen(date));
    }
    nextOf(session) {
        const currentIndex = this._sessions.findIndex(item => item.equals(session));
        if (currentIndex === -1) {
            throw new Error(`Session not in MarketHours ${this.name}`);
        }
        return currentIndex + 1 >= this._sessions.length
            ? this._sessions[0]
            : this._sessions[currentIndex + 1];
    }
    previousOf(session) {
        const currentIndex = this._sessions.findIndex(item => item.equals(session));
        return currentIndex - 1 < 0
            ? this._sessions[this._sessions.length - 1]
            : this._sessions[currentIndex - 1];
    }
    map(action) {
        return this._sessions.map(action);
    }
}
class TwseMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('09:00:00');
    }
    intradayClosing() {
        return TimeOnly.parse('13:30:00');
    }
    constructor() {
        super('TWSE Market Hours', 0);
        for (let i = 1; i < 6; i++) {
            const session = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('09:00:00')), TradingSessionTime.create(i, TimeOnly.parse('13:30:00')));
            this._sessions.push(session);
        }
    }
}
class TfeStandardMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('15:00');
    }
    intradayClosing() {
        return TimeOnly.parse('13:45');
    }
    constructor() {
        super('TfeStandard', 1);
        for (let i = 1; i < 6; i++) {
            const session1 = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('08:45')), TradingSessionTime.create(i, TimeOnly.parse('13:45')));
            const session2 = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('15:00')), TradingSessionTime.create(i + 1, TimeOnly.parse('05:00')));
            this._sessions.push(session1);
            this._sessions.push(session2);
        }
    }
}
class TfeAMMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('08:45');
    }
    intradayClosing() {
        return TimeOnly.parse('13:45');
    }
    constructor() {
        super('TfeAM', 2);
        for (let i = 1; i < 6; i++) {
            const session = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('08:45')), TradingSessionTime.create(i, TimeOnly.parse('13:45')));
            this._sessions.push(session);
        }
    }
}
class TfePMMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('15:00');
    }
    intradayClosing() {
        return TimeOnly.parse('05:00');
    }
    constructor() {
        super('TfePM', 3);
        for (let i = 1; i < 6; i++) {
            const session = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('15:00')), TradingSessionTime.create(i + 1, TimeOnly.parse('05:00')));
            this._sessions.push(session);
        }
    }
}
class CmeMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('06:00');
    }
    intradayClosing() {
        return TimeOnly.parse('05:00');
    }
    constructor() {
        super('CmeMarketHours', 4);
        for (let i = 1; i < 6; i++) {
            const session = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('06:00')), TradingSessionTime.create(i + 1, TimeOnly.parse('05:00')));
            this._sessions.push(session);
        }
    }
}
class CryptoMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('08:00');
    }
    intradayClosing() {
        return TimeOnly.parse('08:00');
    }
    constructor() {
        super('Crypto', 5);
        for (let i = 0; i < 7; i++) {
            const nextWeekday = i + 1 >= 7 ? 0 : i + 1;
            const session = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('08:00')), TradingSessionTime.create(nextWeekday, TimeOnly.parse('08:00')));
            this._sessions.push(session);
        }
    }
}
class SgxCnMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('09:00');
    }
    intradayClosing() {
        return TimeOnly.parse('05:15');
    }
    constructor() {
        super('SGX:CN', 6);
        for (let t = 1; t < 6; t++) {
            const session = TradingSession.create(TradingSessionTime.create(t, TimeOnly.parse('09:00')), TradingSessionTime.create(t, TimeOnly.parse('16:35')));
            this._sessions.push(session);
            const session2 = TradingSession.create(TradingSessionTime.create(t, TimeOnly.parse('17:00')), TradingSessionTime.create(t + 1, TimeOnly.parse('05:15')));
            this._sessions.push(session2);
        }
    }
}
class HkfMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('09:15');
    }
    intradayClosing() {
        return TimeOnly.parse('03:00');
    }
    constructor() {
        super('HKF MarketHours', 6);
        for (let i = 1; i < 6; i++) {
            const session = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('09:15')), TradingSessionTime.create(i, TimeOnly.parse('12:00')));
            this._sessions.push(session);
            const session2 = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('13:00')), TradingSessionTime.create(i, TimeOnly.parse('16:30')));
            this._sessions.push(session2);
            const session3 = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('17:15')), TradingSessionTime.create(i + 1, TimeOnly.parse('03:00')));
            this._sessions.push(session3);
        }
    }
}
class EurexMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('08:15');
    }
    intradayClosing() {
        return TimeOnly.parse('05:00');
    }
    constructor() {
        super('EUREX MarketHours', 6);
        for (let i = 1; i < 6; i++) {
            const nextWeekDay = i + 1 === 6 ? 1 : i + 1;
            const session = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('08:15')), TradingSessionTime.create(nextWeekDay, TimeOnly.parse('05:00')));
            this._sessions.push(session);
        }
    }
}
class IusMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('06:00');
    }
    intradayClosing() {
        return TimeOnly.parse('05:00');
    }
    constructor() {
        super('IUS MarketHours', 7);
        for (let i = 1; i < 6; i++) {
            const session = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('06:00')), TradingSessionTime.create(i + 1, TimeOnly.parse('05:00')));
            this._sessions.push(session);
        }
    }
}
class UndefinedMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('08:00');
    }
    intradayClosing() {
        return TimeOnly.parse('08:00');
    }
    constructor() {
        super('UndefinedMarketHours', 8);
        for (let i = 1; i < 6; i++) {
            const session = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('08:00')), TradingSessionTime.create(i + 1, TimeOnly.parse('08:00')));
            this._sessions.push(session);
        }
    }
}
class CbtAgriculturalMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('09:00:00');
    }
    intradayClosing() {
        return TimeOnly.parse('03:20:00');
    }
    constructor() {
        super('CbtAgriculturalMarketHours', 9);
        for (let i = 1; i < 6; i++) {
            const session = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('09:00:00')), TradingSessionTime.create(i + 1, TimeOnly.parse('03:20:00')));
            this._sessions.push(session);
        }
    }
}
class CbtMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('05:00:00');
    }
    intradayClosing() {
        return TimeOnly.parse('06:00:00');
    }
    constructor() {
        super('CbtMarketHours', 10);
        for (let i = 1; i < 6; i++) {
            const session = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('06:00:00')), TradingSessionTime.create(i + 1, TimeOnly.parse('05:00:00')));
            this._sessions.push(session);
        }
    }
}
class IusSoftMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('16:45');
    }
    intradayClosing() {
        return TimeOnly.parse('01:30');
    }
    constructor() {
        super('IUS Soft', 11);
        for (let i = 1; i < 6; i++) {
            const session = TradingSession.create(TradingSessionTime.create(i, TimeOnly.parse('16:45')), TradingSessionTime.create(i + 1, TimeOnly.parse('01:30')));
            this._sessions.push(session);
        }
    }
}
class SgxTwnMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('08:45');
    }
    intradayClosing() {
        return TimeOnly.parse('05:15');
    }
    constructor() {
        super('SGX:TWN', 12);
        for (let t = 1; t < 6; t++) {
            const session = TradingSession.create(TradingSessionTime.create(t, TimeOnly.parse('08:45')), TradingSessionTime.create(t, TimeOnly.parse('13:50')));
            this._sessions.push(session);
            const session2 = TradingSession.create(TradingSessionTime.create(t, TimeOnly.parse('14:15')), TradingSessionTime.create(t + 1, TimeOnly.parse('05:15')));
            this._sessions.push(session2);
        }
    }
}
class SgxNkMarketHours extends MarketHours {
    intradayOpening() {
        return TimeOnly.parse('07:30');
    }
    intradayClosing() {
        return TimeOnly.parse('05:15');
    }
    constructor() {
        super('SGX:NK', 13);
        for (let t = 1; t < 6; t++) {
            const session = TradingSession.create(TradingSessionTime.create(t, TimeOnly.parse('07:30')), TradingSessionTime.create(t, TimeOnly.parse('14:30')));
            this._sessions.push(session);
            const session2 = TradingSession.create(TradingSessionTime.create(t, TimeOnly.parse('14:55')), TradingSessionTime.create(t + 1, TimeOnly.parse('05:15')));
            this._sessions.push(session2);
        }
    }
}
MarketHours.TWSE = new TwseMarketHours();
MarketHours.TFE_Standard = new TfeStandardMarketHours();
MarketHours.TFE_AM = new TfeAMMarketHours();
MarketHours.TFE_PM = new TfePMMarketHours();
MarketHours.CME = new CmeMarketHours();
MarketHours.Crypto = new CryptoMarketHours();
MarketHours.SGX_CN = new SgxCnMarketHours();
MarketHours.HKF = new HkfMarketHours();
MarketHours.EUREX = new EurexMarketHours();
MarketHours.IUS = new IusMarketHours();
MarketHours.Undefined = new UndefinedMarketHours();
MarketHours.CBT = new CbtMarketHours();
MarketHours.CBT_Agricultural = new CbtAgriculturalMarketHours();
MarketHours.IUS_Soft = new IusSoftMarketHours();
MarketHours.SGX_TWN = new SgxTwnMarketHours();
MarketHours.SGX_NK = new SgxNkMarketHours();
